.bar {
  background-color: #777;
  display: block;
  width: 30px;
  height: 30px;
  height: 2px;
  transition: all 0.5s;
  border-radius: 100px;
}

.bar:first-child {
  transform: translateY(-5px);
}

input[type="checkbox"]:checked ~ #menu {
  display: flex;
  flex-direction: column;
  transition: all 5s;
}

input[type="checkbox"]:checked ~ label > .bar:first-child {
  transition: all 0.5s;
  transform: translateY(2px) rotate(-135deg);
}

input[type="checkbox"]:checked + label > .bar:last-child {
  transition: all 0.5s;
  transform: rotate(135deg);
}

nav a:hover {
  color: $accent;
}
