$primary: #009691;
$accent: #ec407a;
$gray: #4a4a4a;
$light-gray: #cbcbcb;

.bg-primary {
  background-color: $primary;
}

.primary {
  color: $primary;
}

.error {
  color: $accent;
}

.bg-accent {
  background-color: $accent;
}

.accent {
  color: $accent;
}

.fill-white {
  fill: white;
}

.app-gray {
  color: $gray;
}

.card-shadow {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
}

.nav-shadow {
  box-shadow: 0 10px 40px rgba(0, 150, 145, 0.15);
}

.active {
  color: $accent;
}

.hover-accent:hover {
  color: $accent;
}
